














import { Component, Vue } from "vue-property-decorator";
import api from "@/api";

import withdrawalYX from "@/views/integral/components/withdrawalYX.vue";
import withdrawalCK from "@/views/integral/components/withdrawalCK.vue";



@Component({
  components: {
    withdrawalYX,
    withdrawalCK
  }
})
export default class AUser extends Vue {




}
