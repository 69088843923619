

















































































































import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
import IdentifyDialog from '../components/identifyDialog2.vue'
import { base64DownloadFile} from '@/utils/exportFile'

@Component({
  components: {
    IdentifyDialog
  }
})
export default class AUser extends Vue {
  step = 1
  showTalbe = true
  config = {
    jiaoyi: {
      0: '冻结',
      1: '入账',
      2: '兑换'
    },
    type: {
      'zhike': '直客',
      'broker': '经纪人'
    },
    status: {
      1: '已转账',
      2: '已申请'
    }
  }
  // 表格数据
  tableData = [];
  // 加载状态
  loading = false;
  // 总条数
  total = 0;
  // 搜索条件
  search: any = {
    pagination: {
      page: 0, //页码，从0开始
      pageSize: 20, //每页数量
    },
  };
  protected changePage(page: number) {
    this.search.pagination.page = page - 1;
    if(this.step == 1) {
      this.searchTable();
    } else {
      this.approval('sure')
    }

  }
  // 搜索 search:true 重置翻页
  searchTable(search = false) {
    this.loading = true;
    if (search) {
      this.search.pagination = {
        pageSize: 20,
        page: 0,
      };
    }
    api
            .post('J27903', this.search)
            .then(res => {
              this.total = res.total;
              this.tableData = res.list;
            }).finally(() => {
      this.loading = false;
    })
  }

  toDetail(examineNumber: any) {
    this.$router.push({
      name: 'approal',
      query: {
        cz: 'look',
        examineNumber: examineNumber,
        from: 'CK'
      }
    })
  }

  //
  multipleSelection = []
  handleSelectionChange(val: any) {
    console.log(val);
    this.multipleSelection = val;
  }

  // 审批
  approval(val: any) {
    this.search.pagination.page = 0
    if(val =='cancle') {
      this.step = 1
      this.searchTable();
      this.showTalbe = false
      this.$nextTick(() => {
        this.showTalbe = true
      })
      return
    }
    this.loading = true
    api
      .post('J27904', this.search)
      .then(res => {
        this.step = 2
        this.showTalbe = false
        this.$nextTick(() => {
          this.showTalbe = true
        })
        this.tableData = res.list
        this.total = 0

      }).finally(() => {
      this.loading = false
    })

  }

  // 提交审批
  approvalDialog = false
  submitOpinions = ''
  allmoney = ''
  setApproval() {
    if(this.multipleSelection.length == 0) {
      this.$message.warning('请选中数据后再提交')
      return
    }
    // 提现金额统计
    let _allmoney = 0;
    this.multipleSelection.forEach((x:any)=>{
      _allmoney += parseFloat(x.truthAmount);
    })
    this.allmoney = _allmoney.toFixed(2)
    this.approvalDialog = true
  }
  handleClose() {
    this.approvalDialog = false
  }
  isBool = true
  sureApproval() {
    if(!this.isBool) {
      return;
    }
    this.isBool = false
    let codes: any = []
    this.multipleSelection.forEach((item: any) => {
      codes.push(item.truthId)
    })
    if(this.submitOpinions == '') {
      this.$message.warning('提交意见不能为空')
      return
    }

    api
      .post('J27902', {codes: codes, submitOpinions: this.submitOpinions})
      .then(res => {
        this.approvalDialog = false
        this.step = 1
        this.showTalbe = false
        this.$nextTick(() => {
          this.showTalbe = true
        })
        this.$message.success('操作成功')
        this.searchTable()
      }).finally(()=> {
      this.isBool = true
    })

  }


  // 导出
  exportLoad = false
  exportD() {
    this.exportLoad = true
    api
            .post('J27121', this.search)
            .then(res => {
              this.download(res)
            }).finally(() => {
      this.exportLoad =false
    })
  }

  download(res: any) {
    api
            .post('J27143', {filePath: res})
            .then(res => {
              base64DownloadFile('积分提现表.xlsx',res)
            })
  }

  // 转账上传
  private identifyDialog = {
    show: false,
    title: '智能识别'
  }
  protected identify() {
    this.identifyDialog.show = true
  }
  protected closeIdentifyDialog(val: any) {
    this.identifyDialog.show = false
    if(val){
      this.searchTable();
    }
  }

  activated() {
    this.searchTable();
  }
}
